global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 1,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: [
    "tigros.it",
    "test.tigros.it",
    "www.tigros.it",
    "test-tigros.digitelematica.org",
    "dev-tigros.digitelematica.org",
    "localhost"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsntigros://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/recover-password-confirm": "/recover-password-confirm",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed",
    "/registration/confirm/:status": "/registration/confirm/:status",
    "/registration/thankyou": "/registration/thankyou"
  },
  // filterProductClasses: [1, 2, 3, 4, 6],
  updateCart: true,
  dialogMaxWidth: 530,
  addToFavoritesWidth: 350,
  analyticsClass: "analyticsServiceGTM",
  cartItemInfos: ["size", "customWeight"],
  acceptAlternatives: false,
  productVoteMaxRating: 5,
  categoryTreeDepth: 4,
  customCountries: [
    {
      itemId: "1",
      iso: "IT",
      name: "Italia"
    },
    {
      itemId: "211",
      iso: "CH",
      name: "Svizzera"
    }
  ],
  facebookClientId: "1250974205313975",
  googleClientId:
    "1061314893204-tbicvnnsq74cjiarigmq3jiilqu56b0v.apps.googleusercontent.com",
  perksWarehouseId: 42,
  perksCatalogRestrictionId: 1,
  perksCategoryId: 148000574,
  showProductsByDate: {
    show: true,
    minDate: "2023-07-03",
    maxDate: "2023-07-31" // SET THE DAY AFTER THE CHOSEN DAY AS MAXDATE
  },
  defaultPaymentTypeId: 12,
  socialLogo: "/logo/social.png",
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: [
    "/ebsn/api/tigros-registration/update_user_detail",
    "/ebsn/api/registration/user_detail",
    "/ebsn/api/auth/login",
    "/ebsn/api/checkout/buy",
    "/ebsn/api/registration/password_reset_token_request",
    "/ebsn/api/registration/password_reset_token_check",
    "/ebsn/api/tigros-registration/register_user",
    "/ebsn/api/registration/update_password",
    "/ebsn/api/tigros-registration/update_user_data_for_ticket",
    "/ebsn/api/tigros-registration/update_user_ticket_enabled",
    "/ebsn/api/tigros-registration/find_user_for_ticket",
    "/ebsn/api/tigros-registration/find_user",
    "/ebsn/api/tco/tco_detail"
  ],
  defaultStoreName: process.env.VUE_APP_DEFAULT_STORE_NAME,
  defaultPageName: "Tigros",
  defaultBreakpoints: {
    xs: { slidesPerView: 1, slidesPerGroup: 1 },
    sm: { slidesPerView: 2, slidesPerGroup: 1 },
    md: { slidesPerView: 3, slidesPerGroup: 1 },
    lg: { slidesPerView: 4, slidesPerGroup: 1 },
    xl: { slidesPerView: 6, slidesPerGroup: 1 }
  },
  breakpointsMap: [
    { xs: 0 },
    { sm: 600 },
    { md: 960 },
    { lg: 1264 },
    { xl: 1900 }
  ]
};
